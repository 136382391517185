<template>
  <v-container
    v-if="showAuthProgress"
  >
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
      class="overlay-style"
    >
      <div
        class="alert-class"
      >
        <p>{{ authenticationProcessAlert }}</p>
        <v-row>
          <v-progress-linear
            color="#FF3700"
            indeterminate
            rounded
            height="10"
            value="100"
          />
        </v-row>
      </div>
    </v-overlay>
  </v-container>
</template>
<script>

  export default {
    name: 'PosterMyWallOauthStepTwo',
    data () {
      return {
        showAuthProgress: false,
        authenticationProcessAlert: '',
        absolute: false,
        opacity: 0.6,
        zIndex: 5,
      };
    },
    mounted () {
      this.showAuthProgress = true;
      this.authenticationProcessAlert = 'Verifying...';
      setTimeout(() => {
        this.passPosterMyWallUserAuthDetails();
      }, 3000);
    },
    methods: {
      async passPosterMyWallUserAuthDetails () {
        this.authenticationProcessAlert = 'Registering...';
        await this.$store.dispatch('contents/passPosterMyWallUserAuthDetails', {
          authorization_code: this.$route.query.code,
          state: 'STATE',
        }).then(response => {
          this.showAuthProgress = false;
          this.$router.push({ name: 'PosterMyWall Editor Landing Page' });
        }).catch(() => {
          this.$router.push({ name: 'Media Library' });
        });
      },
    },
  };
</script>

<style scoped>
.alert-class {
  font-size: 30px;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  position: absolute;
}
@media (max-width: 767px) {
  .alert-class {
  position: static;
  text-align: center;
  }
}
</style>
